//TODO unify with the AmountDto and make sure to use the right data type for the amount

// This should be done after removing the old backend models that may also use AmountDto
export interface MoneyDto {
  amount: string;
  ccyIso: string;
}

export interface AccountOverviewDto {
  total: MoneyDto;
  accountGroups: Array<AccountGroupDto>;
}

export interface AccountGroupDto {
  accountCategory: AccountCategory;
  nameDe: string;
  nameEn: string;
  totalValue: MoneyDto;
  accounts: Array<AccountDto>;
}

export interface AccountDto {
  id?: string;
  accountNo: string;
  currentValues: AccountCurrentDto;
  name: string;
  iban?: string;
  ccyIsoCode: string;
  limit?: MoneyDto;
  powerOfAttorneyType?: PowerOfAttorneyType;
  paymentAllowed?: boolean; //TODO the field is optional for compatibility with the old backend
  hasDetails: boolean;
  cashAccountType?: CashAccountType;
}

export enum AccountCategory {
  Others = 'OTHERS',
  Savings = 'SAVINGS',
  Credit = 'CREDIT',
  Cash = 'CASH',
  Timedeposit = 'TIMEDEPOSIT',
  Securities = 'SECURITIES',
}

export interface AccountCurrentDto {
  bookedBalance: MoneyDto;
}

export enum PowerOfAttorneyType {
  SinglePOA = 'SINGLEPOA',
  JointPOA = 'JOINTPOA',
  InfoPOA = 'INFOPOA',
}

export interface CashAccountTransactionView {
  pendingPayments: Array<CashAccountTransactionDto>;
  transactions: Array<CashAccountTransactionDto>;
}

export interface CashAccountTransactionDto {
  id: string;
  transactionCode?: Number;
  creditCardTransactionCode?: Number;
  cashAccountId: string;
  amount: MoneyDto;
  purposeCode: CashAccountTransactionPurposeCodeDto;
  bookingDate: string;
  valueDate: string;
  descriptionLines: Array<string>;
  counterPartyNameLines: Array<string>;
  counterPartyBic: string;
  counterPartyIban: string;
  instructedAmount?: MoneyDto;
  ultimateDebtor: string;
  ultimateCreditor: string;
  endToEndId: string;
  currencyExchangeRate: string;
  exchangeFee?: MoneyDto;
}

export interface CashAccountTransactionFilterOptionsDto {
  purposeCodes: Array<CashAccountTransactionPurposeCodeDto>;
  turnOverTypes: TransactionTurnOverType;
}

export enum TransactionTurnOverType {
  Inflow = 'INFLOW',
  Outflow = 'OUTFLOW',
}

export interface CashAccountTransactionPurposeCodeDto {
  id: string;
  code: string;
  descriptionDe: string;
  descriptionEn: string;
}

export enum PaymentOrderStatus {
  New = 'NEW',
  ChangeNew = 'CHANGE_NEW',
  DeleteNew = 'DELETE_NEW',
  Approved = 'APPROVED',
  DeleteApproved = 'DELETE_APPROVED',
  Scheduled = 'SCHEDULED',
  Processed = 'PROCESSED',
  Failed = 'FAILED',
}

export interface CashAccountInformationDto {
  initialDate?: string;
  maturityStartDate?: string;
  maturityEndDate?: string;
  interestRate?: string;
  borrowingRate?: string;
  transferLimit?: MoneyDto;
  existingCard?: boolean;
  timeDepositAccountProlongationIsPossible?: boolean;
  limits?: AccountLimitDto[]; // only for old backend compatability
}

export interface AccountLimitDto {
  accountId?: string; // optional for compatibility with the old backend
  userId?: string; // optional for compatibility with the old backend
  userName: string;
  transactionLimit: MoneyDto;
}

export enum CashAccountType {
  OvernightLoan = 'OVERNIGHT_LOAN',
  FixedTermDepositLoan = 'FIXED_TERM_DEPOSIT_LOAN',
  SubordinatedLoan = 'SUBORDINATED_LOAN',
  Rollover = 'ROLLOVER',
  TermLoan = 'TERM_LOAN',
  RedeemableLoan = 'REDEEMABLE_LOAN',
  HomeLoanRollover = 'HOME_LOAN_ROLLOVER',
  HomeLoanLimitedTermLoan = 'HOME_LOAN_LIMITED_TERM_LOAN',
  HomeLoanRedeemableLoan = 'HOME_LOAN_REDEEMABLE_LOAN',
  RentSurety = 'RENT_SURETY',
  FiLoan = 'FI_LOAN',
  CreditCard = 'CREDIT_CARD',
  CurrentAccount = 'CURRENT_ACCOUNT',
  OvernightDeposit = 'OVERNIGHT_DEPOSIT',
  MarginCallAccount = 'MARGIN_CALL_ACCOUNT',
  SettlementAccountDiscretionaryPortfolio = 'SETTLEMENT_ACCOUNT_DESCRETIONARY_PORTFOLIO',
  WagesAndSalaryAccount = 'WAGES_AND_SALARY_ACCOUNT',
  NonAdvisoryAccount = 'NON_ADVISORY_ACCOUNT',
  CustodyAccount = 'CUSTODY_ACCOUNT',
  FtgAccount = 'FTG_ACCOUNT',
  SavingsDepositStatutoryNoticePeriod = 'SAVINGS_DEPOSIT_STATUTORY_NOTICE_PERIOD',
  SavingsAnualNoticePeriod = 'SAVINGS_ANUAL_NOTICE_PERIOD',
  SavingsDepositNoticePeriod4Years = 'SAVINGS_DEPOSIT_NOTICE_PERIOD_4_YEARS',
  TimeDepositAccount = 'TIME_DEPOSIT_ACCOUNT',
  EscrowAccount = 'ESCROW_ACCOUNT',
  SaleAndPurchase = 'SALE_AND_PURCHASE',
  NonInterestTaxAccount = 'NON_INTEREST_TAX_ACCOUNT',
}

// in case of the old backend, the limits should be included in the information
export interface AccountSpecificationsModel {
  information: CashAccountInformationDto;
  limits: AccountLimitDto[] | null;
}
