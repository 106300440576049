import { RmpDocument } from '@models/rmp.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const FEATURE_KEY = 'rmp';
export const featureAdapter: EntityAdapter<any> = createEntityAdapter<any>({});

export interface State extends EntityState<any> {
  isLoading: boolean;
  error: string | null;
  isRmpLoginSuccessful: boolean;
  rmpDocuments: RmpDocument[];
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  clients: [],
  isRmpLoginSuccessful: false,
  rmpDocuments: [],
});
