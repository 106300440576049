import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';

import { shareReplay, tap } from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';
import { ConfigService } from './config/config.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private observable$?: Observable<any>;
  constructor(private http: HttpClient, private configService: ConfigService) {}

  private mockedToggles = new Map<string, boolean>([
    ['WEB_USE_NEW_AUTH_SERVICE', true],
  ]);

  private featureMap?: Map<string, boolean>;

  getFeatureToggles(): Observable<any> {
    if (!this.observable$) {
      const featureEndpoint$ = this.http
        .get<Object>(`${env.feature_endpoint}`)
        .pipe(shareReplay(1));
      const newFeatureEndpoint$ = this.http
        .get<Object>(`${env.feature_endpoint_new}`)
        .pipe(shareReplay(1));

      this.observable$ = forkJoin([featureEndpoint$, newFeatureEndpoint$]).pipe(
        tap(([featureResult, newFeatureResult]) => {
          this.featureMap = new Map<string, boolean>(
            Object.entries(featureResult)
          );

          const newFeatureMap = new Map<string, boolean>(
            Object.entries(newFeatureResult)
          );
          for (let [key, value] of newFeatureMap) {
            if (!this.featureMap.has(key)) {
              this.featureMap.set(key, value);
            }
          }
        })
      );
    }

    return this.observable$;
  }

  getFeatureToggleByKey(key: string): boolean {
    try {
      if (this.configService.getConfig('featureService')?.preview === true) {
        if (this.mockedToggles.has(key)) {
          return this.mockedToggles.get(key)!;
        }
      }

      return this.featureMap?.get(key) as boolean;
    } catch (error) {
      console.error(`getFeatureToggleByKey ${key} failed: ${error}`);
      return false;
    }
  }
}
