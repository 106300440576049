import { RmpDocument } from '@models/rmp.model';
import { Action } from '@ngrx/store';
import * as ActionTypes from './actionTypes';

export class LoadFailure implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadRmpDocumentsRequest implements Action {
  readonly type = ActionTypes.LOAD_RMP_DOCUMENTS_REQUEST;
}

export class LoadRmpDocumentsSuccess implements Action {
  readonly type = ActionTypes.LOAD_RMP_DOCUMENTS_SUCCESS;
  constructor(public payload: RmpDocument[]) {}
}
