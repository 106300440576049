import { Injectable } from '@angular/core';
import { RmpDataService } from '@core/services/rmp/rmp.service';
import { RmpDocument } from '@models/rmp.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as FeatureActions from './actions';
import * as ActionTypes from './actionTypes';

@Injectable()
export class RmpEffects {
  loadRmpDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.LOAD_RMP_DOCUMENTS_REQUEST),
      mergeMap((action: FeatureActions.LoadRmpDocumentsRequest) =>
        this.rmpService.getRmpDocuments().pipe(
          map(
            (data: RmpDocument[]) =>
              new FeatureActions.LoadRmpDocumentsSuccess(data)
          ),
          catchError((error) => {
            this.store$.dispatch(
              new FeatureActions.LoadFailure({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private store$: Store,
    private rmpService: RmpDataService
  ) {}
}
