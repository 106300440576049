import { RmpDocument } from '@models/rmp.model';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { FEATURE_KEY, State } from './state';

export const getError = (state: State): string | null => state.error;
export const getIsRmpLoginSuccessful = (state: State): boolean =>
  state.isRmpLoginSuccessful;
export const getDocuments = (state: State): RmpDocument[] => state.rmpDocuments;

export const selectState: MemoizedSelector<object, State> =
  createFeatureSelector<State>(FEATURE_KEY);

export const selectError: MemoizedSelector<object, any> = createSelector(
  selectState,
  getError
);
export const selectIsRmpLoginSuccessful: MemoizedSelector<object, boolean> =
  createSelector(selectState, getIsRmpLoginSuccessful);
export const selectDocuments: MemoizedSelector<object, RmpDocument[]> =
  createSelector(selectState, getDocuments);
