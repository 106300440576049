import * as ActionTypes from './actionTypes';
import { initialState, State } from './state';

export function rmpReducer(
  state: State = initialState,
  action: any = {}
): State {
  switch (action.type) {
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_RMP_DOCUMENTS_REQUEST: {
      return {
        ...state,
        error: null,
        rmpDocuments: [],
      };
    }

    case ActionTypes.LOAD_RMP_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        error: null,
        rmpDocuments: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
