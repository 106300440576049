import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientDto, ClientsDto } from '@models/entitlements/client.model';
import { RmpDocument } from '@models/rmp.model';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RmpDataService {
  constructor(private http: HttpClient) {}

  public getClients(search: string): Observable<ClientDto[]> {
    return this.http
      .get<ClientsDto>(`/api/v1-legacy/entitlements/clients?search=${search}`)
      .pipe(map((data) => data.clients));
  }

  public getRmpDocuments(): Observable<RmpDocument[]> {
    return this.http.get<RmpDocument[]>('/public/api/v1/rmp/documents');
  }
}
