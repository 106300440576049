export const LOAD_ACCOUNT_TRANSACTIONS_REQUEST =
  '[Account] Load Account Transactions Request';
export const LOAD_ACCOUNT_TRANSACTIONS_SUCCESS =
  '[Account] Load Account Transactions Success';
export const LOAD_ACCOUNT_POSITIONS_REQUEST =
  '[Account] Load Account Positions Request';
export const LOAD_ACCOUNT_POSITIONS_SUCCESS =
  '[Account] Load Account Positions Success';
export const LOAD_MORE_ACCOUNT_TRANSACTIONS_REQUEST =
  '[Account] Load More Account Transactions Request';
export const LOAD_MORE_ACCOUNT_TRANSACTIONS_SUCCESS =
  '[Account] Load More Account Transactions Success';
export const SET_ITEMS_PER_PAGE = '[Account] Set Items per Page';
export const SET_SELECTED_ACCOUNT = '[Account] Set Selected Account';
export const LOAD_FAILURE = '[Account] Load Failure';
export const SET_FILTER = '[Account] Set Account Transactions Filter';
export const CLEAR_FILTER = '[Account] Clear Account Transactions Filter';
export const LOAD_PAYMENT_ACCOUNTS_REQUEST =
  '[Account] Load Payment Accounts Request';
export const LOAD_PAYMENT_ACCOUNTS_SUCCESS =
  '[Account] Load Payment Accounts Success';
export const LOAD_PURPOSE_CODES_REQUEST =
  '[Account] Load Purpose Codes Request';
export const LOAD_PURPOSE_CODES_SUCCESS =
  '[Account] Load Purpose Codes Success';
export const EXPORT_TRANSACTIONS_AS_CSV_REQUEST =
  '[Account] Export Transactions as CSV Request';
export const EXPORT_TRANSACTIONS_AS_CSV_SUCCESS =
  '[Account] Export Transactions as CSV SUCCESS';
export const TRANSACTION_PRINT_REQUEST = '[Account] Transaction Print Request';
export const TRANSACTION_PRINT_SUCCESS = '[Account] Transaction Print Success';
export const TRANSACTION_PRINT_FAILURE = '[Account] Transaction Print Failure';
export const TRANSACTION_PRINT_PENDING_REQUEST =
  '[Account] Transaction Print Pending Request';
export const TRANSACTION_PRINT_PENDING_SUCCESS =
  '[Account] Transaction Print Pending Success';
export const TRANSACTION_PRINT_PENDING_FAILURE =
  '[Account] Transaction Print Pending Failure';
export const TRANSACTION_PRINT_STATUS = '[Account] Transaction Print Status';
export const TRANSACTION_PRINT_STATUS_SUCCESS =
  '[Account] Transaction Print Status Success';
export const TRANSACTION_PRINT_STATUS_FAILURE =
  '[Account] Transaction Print Status Failure';
