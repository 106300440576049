import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AccountTransactionModel,
  convertAccountOverviewDto,
  convertCashTransactionDto,
  convertPendingPaymentDto,
  convertPurposeCodeDto,
  PendingPaymentsModel,
  PrintStatusType,
  ProductSummaryModel,
  PurposeCodesModel,
  TransactionsActionParams,
} from '@models/account.model';
import {
  AccountDto,
  AccountLimitDto,
  AccountOverviewDto,
  CashAccountInformationDto,
  CashAccountTransactionDto,
  CashAccountTransactionFilterOptionsDto,
  CashAccountTransactionView,
  MoneyDto,
} from '@models/account.model.new';
import { Store } from '@ngrx/store';
import { AccountActions, AccountStore } from '@store/account-store';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, delay, map, repeat, tap } from 'rxjs/operators';
import { FeatureService } from '../feature.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(
    private http: HttpClient,
    private store$: Store<AccountStore.State>,
    private featureService: FeatureService
  ) {}

  accountOverviewSubject = new BehaviorSubject<AccountOverviewDto | null>(null);

  getSelectedAccount(accountNo: string | null): Observable<AccountDto | null> {
    if (accountNo) {
      return this.getAccountOverview().pipe(
        map((accountOverview) => {
          return (
            accountOverview.accountGroups
              .flatMap((group) => group.accounts)
              .find((account) => account.accountNo === accountNo) ?? null
          );
        })
      );
    }

    return of(null);
  }

  getAccountOverview(
    opt: { forceFetch: boolean } = { forceFetch: false }
  ): Observable<AccountOverviewDto> {
    if (!opt.forceFetch && this.accountOverviewSubject.value) {
      return of(this.accountOverviewSubject.value);
    }

    if (
      this.featureService.getFeatureToggleByKey(
        'USE_NEW_BACKEND_FOR_ACCOUNT_LIST_AND_BALANCES'
      )
    ) {
      return this.http.get<AccountOverviewDto>('/api/v1-legacy/accounts').pipe(
        tap((value) => {
          this.accountOverviewSubject.next(value);
        })
      );
    } else {
      return this.http
        .get<ProductSummaryModel>('/public/api/v2/productsummary')
        .pipe(
          map(convertAccountOverviewDto),
          tap((value) => {
            this.accountOverviewSubject.next(value);
          })
        );
    }
  }

  getAccountTransactions(
    payload: any,
    fetchPendingPayments: Boolean = true
  ): Observable<CashAccountTransactionView> {
    const cashAccountObj = of(<CashAccountTransactionDto[]>{});
    if (
      this.featureService.getFeatureToggleByKey(
        'USE_NEW_BACKEND_FOR_ACCOUNT_TRANSACTIONS'
      )
    ) {
      if (
        this.featureService.getFeatureToggleByKey(
          'USE_NEW_BACKEND_FOR_PAYMENTS'
        )
      ) {
        const params = this.createFilterParams(payload, fetchPendingPayments);
        return forkJoin({
          pendingPayments: cashAccountObj,
          transactions: this.http.get<CashAccountTransactionDto[]>(
            `/api/v1-legacy/accounts/${payload.accountNo}/transactions${params}`
          ),
        });
      } else {
        const params = this.createFilterParams(payload, false);
        return forkJoin({
          pendingPayments:
            payload.page == 0 && fetchPendingPayments
              ? this.getAccountTransactionsPendingPayments(payload)
              : cashAccountObj,
          transactions: this.http.get<CashAccountTransactionDto[]>(
            `/api/v1-legacy/accounts/${payload.accountNo}/transactions${params}`
          ),
        });
      }
    } else {
      const params = this.createFilterParamsOldBE(payload);
      return forkJoin({
        pendingPayments: cashAccountObj,
        transactions: this.http
          .get<AccountTransactionModel[]>(
            `/public/api/v2/transactions${params}`
          )
          .pipe(map(convertCashTransactionDto)),
      });
    }
  }

  getAccountTransactionsExport(
    accountId: string,
    payload: any
  ): Observable<string> {
    const params = this.createTransactionsExportFilterParams(payload);
    return this.http.get(
      `/api/v1/accounts/${accountId}/transactions${params}`,
      { responseType: 'text', headers: { Accept: 'text/csv' } }
    );
  }

  getAccountTransactionsPendingPayments(
    payload: any
  ): Observable<CashAccountTransactionDto[]> {
    const params = this.createFilterParams(payload, undefined);
    return this.http
      .get<PendingPaymentsModel[]>(
        `/public/api/wmp/v1/transactions/${payload.accountNo}/pending-payments${params}`
      )
      .pipe(map(convertPendingPaymentDto));
  }

  getPurposeCodes(
    accountNo: any
  ): Observable<CashAccountTransactionFilterOptionsDto> {
    if (
      this.featureService.getFeatureToggleByKey(
        'USE_NEW_BACKEND_FOR_ACCOUNT_TRANSACTIONS'
      )
    ) {
      return this.http.get<CashAccountTransactionFilterOptionsDto>(
        `/api/v1-legacy/accounts/${accountNo}/transaction-filter-options`
      );
    } else {
      return this.http
        .get<PurposeCodesModel[]>(
          `/public/api/v2/transactions/${accountNo}/purpose-codes`
        )
        .pipe(map(convertPurposeCodeDto));
    }
  }

  exportAccountTransactions(payload: any): Observable<any> {
    let params = '';
    if (payload.timePeriodFrom) {
      const parameter = `timePeriodFrom=${payload.timePeriodFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.timePeriodTo) {
      const parameter = `timePeriodTo=${payload.timePeriodTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    return this.http.get(
      `/public/api/v2/account-transaction-export/${payload.accountId}${params}`,
      {
        responseType: 'text',
      }
    );
  }

  getAccountInformation(
    accountNo: string
  ): Observable<CashAccountInformationDto> {
    if (
      this.featureService.getFeatureToggleByKey(
        'USE_NEW_BACKEND_FOR_TIME_DEPOSIT_USER_PROLONGATION'
      )
    ) {
      return this.http.get<CashAccountInformationDto>(
        `/api/v1-legacy/accounts/${accountNo}/information`
      );
    } else {
      return this.http
        .get<any>(`/public/api/v2/productsummary/${accountNo}/specifications`)
        .pipe(map(this.mapOldSpecificationsToNew));
    }
  }

  private mapOldSpecificationsToNew(
    specifications: any
  ): CashAccountInformationDto {
    const limits: AccountLimitDto[] = [];
    if (specifications.additions) {
      for (const [name, value] of Object.entries<string>(
        specifications.additions
      )) {
        limits.push(<AccountLimitDto>{
          userName: name,
          transactionLimit: <MoneyDto>{ amount: value, ccyIso: 'EUR' },
        });
      }
    }

    let existingCard = false;
    if (
      (specifications.existingCard &&
        typeof specifications.existingCard === 'string' &&
        specifications.existingCard.toLowerCase() === 'yes') ||
      specifications.existingCard.toLowerCase() === 'ja'
    ) {
      existingCard = true;
    }

    return <CashAccountInformationDto>{
      initialDate: specifications.initialDate,
      maturityStartDate: specifications.maturityStartDate,
      maturityEndDate: specifications.maturityEndDate,
      interestRate: specifications.interestRatePct,
      borrowingRate: specifications.borrowingRatePct,
      transferLimit: <MoneyDto>{
        amount: specifications.transferLimit,
        ccyIso: 'EUR',
      },
      existingCard: existingCard,
      timeDepositAccountProlongationIsPossible:
        specifications.timeDepositAccountProlongation,
      limits: limits,
    };
  }

  /// Returns null in case the old backend is used
  getAccountLimits(accountId: string): Observable<AccountLimitDto[] | null> {
    if (
      this.featureService.getFeatureToggleByKey(
        'USE_NEW_BACKEND_FOR_TIME_DEPOSIT_USER_PROLONGATION'
      )
    ) {
      return this.http.get<AccountLimitDto[]>(
        `/api/v1/accounts/${accountId}/limits`
      );
    } else {
      return of(null);
    }
  }

  /// account Id for new backend otherwise accountNo for old backend
  toggleUserProlongationRmp(opt: {
    accountNo?: string;
    accountId?: string;
    prolong: boolean;
  }): Observable<any> {
    if (
      this.featureService.getFeatureToggleByKey(
        'USE_NEW_BACKEND_FOR_TIME_DEPOSIT_USER_PROLONGATION'
      )
    ) {
      return this.http.post<boolean>(
        `/api/v1/accounts/${opt.accountId!}/toggle-user-prolongation`,
        ''
      );
    } else {
      return this.http.put<any>(
        `/public/api/v2/productsummary/set-user-prolongation/${opt.accountNo!}`,
        { userProlongation: opt.prolong }
      );
    }
  }

  getPositions(payload: any): Observable<any> {
    let params = '';
    if (payload.search) {
      const parameter = `search=${payload.search}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.amountFrom) {
      const parameter = `fromAmount=${payload.amountFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.amountTo) {
      const parameter = `toAmount=${payload.amountTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    return this.http.get<any>(
      `/public/api/v2/productsummary/${payload.accountId}/positions${params}`
    );
  }

  getPaymentAccounts(): Observable<any> {
    return this.http.get<any>('/public/api/v2/productsummary/payment-accounts');
  }

  private createFilterParams(
    payload: any,
    fetchPendingPayments: Boolean | undefined
  ): string {
    let params =
      fetchPendingPayments != undefined
        ? `?include-pending-payments=${fetchPendingPayments}`
        : '';

    if (payload.amountFrom) {
      const parameter = `amount-from=${payload.amountFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.amountTo) {
      const parameter = `amount-to=${payload.amountTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.dateFrom) {
      const parameter = `date-from=${payload.dateFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.dateTo) {
      const parameter = `date-to=${payload.dateTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.page || payload.page == 0) {
      const parameter = `page=${payload.page}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.search) {
      const parameter = `search=${payload.search}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.size) {
      const parameter = `size=${payload.size}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.purposeCode?.length > 0) {
      payload.purposeCode.forEach((element: any) => {
        const parameter = `transaction-purpose-codes=${element}`;
        params = params.length ? `${params}&${parameter}` : `?${parameter}`;
      });
    }

    return params;
  }

  private createTransactionsExportFilterParams(payload: any): string {
    let params = `?include-pending-payments=true`;

    if (payload.amountFrom) {
      const parameter = `amount-from=${payload.amountFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.amountTo) {
      const parameter = `amount-to=${payload.amountTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.dateFrom) {
      const parameter = `date-from=${payload.dateFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.dateTo) {
      const parameter = `date-to=${payload.dateTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.search) {
      const parameter = `search=${payload.search}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.purposeCode?.length > 0) {
      payload.purposeCode.forEach((element: any) => {
        const parameter = `transaction-purpose-codes=${element}`;
        params = params.length ? `${params}&${parameter}` : `?${parameter}`;
      });
    }

    return params;
  }

  private createFilterParamsOldBE(payload: any): string {
    let params = '';

    if (payload.size) {
      const parameter = `size=${payload.size}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.accountNo) {
      const parameter = `arrangementId=${payload.accountNo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.amountFrom) {
      const parameter = `amountFrom=${payload.amountFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.amountTo) {
      const parameter = `amountTo=${payload.amountTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.dateFrom) {
      const parameter = `dateFrom=${payload.dateFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.dateTo) {
      const parameter = `dateTo=${payload.dateTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.page) {
      const parameter = `from=${payload.page}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.search) {
      const parameter = `search=${payload.search}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.purposeCode?.length > 0) {
      payload.purposeCode.forEach((element: any) => {
        const parameter = `purposeCode=${element}`;
        params = params.length ? `${params}&${parameter}` : `?${parameter}`;
      });
    }

    return params;
  }

  printDocument(params: any): Observable<any> {
    return this.http.post<any>(
      `/public/api/v2/documents/print-account-transaction`,
      params
    );
  }

  printPendingDocument(params: any): Observable<any> {
    return this.http.post<any>(
      `/public/api/v2/documents/print-pending-payment`,
      params
    );
  }

  getPrintedPdfState(param: any): Observable<any> {
    return this.http.get<any>(
      `/public/api/v2/documents/get-printed-pdf-document-state/${param}`
    );
  }

  getPrintedPdf(
    param: any,
    headers: HttpHeaders = new HttpHeaders({})
  ): Observable<any> {
    const uri = `/public/api/v2/documents/get-printed-pdf-document/${param}`;
    return this.http.request<any>('get', uri, {
      headers,
      observe: 'response',
      responseType: 'blob' as 'json',
      withCredentials: false,
    });
  }

  pollPrintStatusResponse(serviceResult: Observable<any>): void {
    this.store$.dispatch(new AccountActions.TransactionPrintStatus());
    const subscription = serviceResult
      .pipe(
        delay(1000),
        catchError((error) => {
          subscription.unsubscribe();
          this.store$.dispatch(
            new AccountActions.TransactionPrintStatusFailure(error)
          );
          return throwError(error);
        }),
        repeat()
      )
      .subscribe((resp: TransactionsActionParams) => {
        if (resp.documentState === PrintStatusType.PRINT_STATE_PROCESSED) {
          this.store$.dispatch(
            new AccountActions.TransactionPrintStatusSuccess(resp)
          );
          subscription.unsubscribe();
        }
      });
  }
}
